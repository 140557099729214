'use client'
// @todo explore Browser logs collection
// https://docs.datadoghq.com/logs/log_collection/javascript/
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'

const clientToken = globalThis.process.env.ENPAL_PUBLIC_DD_CLIENT_TOKEN
const applicationId = globalThis.process.env.ENPAL_PUBLIC_DD_APPLICATION_ID
const env = globalThis.process.env.ENPAL_PUBLIC_DD_ENV
const site = 'datadoghq.eu'
const host =
  typeof window !== 'undefined' &&
  `${window.location.protocol}//${window.location.host}`
export type PropagatorType = 'datadog' | 'b3' | 'b3multi' | 'tracecontext'
const propagatorTypes: PropagatorType[] = ['tracecontext']

if (!applicationId) throw new Error('Missing DD application ID')
if (!clientToken) throw new Error('Missing DD client token')

const allowedTracingUrls = [
  {
    match: /https:\/\/.*\.azurewebsites\.com/,
    propagatorTypes: propagatorTypes,
  },
  {
    match: (url: string) => url.startsWith('https://api-dev.core.enpal.io'),
    propagatorTypes: propagatorTypes,
  },
  {
    match: (url: string) => url.startsWith('https://api-stg.core.enpal.io'),
    propagatorTypes: propagatorTypes,
  },
  {
    match: (url: string) => url.startsWith('https://api.core.enpal.io'),
    propagatorTypes: propagatorTypes,
  },
]

if (host) {
  allowedTracingUrls.push({
    match: (url: string) => url.startsWith(host),
    propagatorTypes: propagatorTypes,
  })
}

datadogLogs.init({
  clientToken: clientToken,
  service: process.env.appName,
  version: process.env.appVersion,
  env,
  site: site,
  forwardErrorsToLogs: true,
  forwardConsoleLogs: 'all',
  sessionSampleRate: 100,
})

datadogRum.init({
  applicationId,
  clientToken,
  site,
  service: process.env.appName,
  version: process.env.appVersion,
  env,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackResources: true,
  trackLongTasks: true,
  trackUserInteractions: true,
  actionNameAttribute: 'data-testid',
  allowedTracingUrls: allowedTracingUrls,
})
